// 防抖
// import Layout from "@/views/menu";

export const debounce = function (fn, delay, cd) {
    let timeout = null;
    return function () {
        clearTimeout(timeout);
        if (cd) {
            cd.apply(this, arguments);
        }
        timeout = setTimeout(() => {
            fn.apply(this, arguments);
        }, delay);
    };
}

// 节流
export const throttle = function (fn, delay) {
    let last = 0;
    let timer = null;
    return function () {
        let now = +new Date();
        if (now - last < delay) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                last = now;
                fn.apply(this, arguments);
            }, delay);
        } else {
            last = now;
            fn.apply(this, arguments);
        }
    };
}

// 验证输入格式
export const checkInput = function (val = "", rule = [], reg = []) {
    /**
     *验证数字的正则表达式集
     验证数字：^[0-9]*$
     验证n位的数字：^\d{n}$
     验证至少n位数字：^\d{n,}$
     验证m-n位的数字：^\d{m,n}$
     验证零和非零开头的数字：^(0|[1-9][0-9]*)$
     验证有两位小数的正实数：^[0-9]+(.[0-9]{2})?$
     验证有1-3位小数的正实数：^[0-9]+(.[0-9]{1,3})?$
     验证非零的正整数：^\+?[1-9][0-9]*$
     验证非零的负整数：^\-[1-9][0-9]*$
     验证非负整数（正整数 + 0） ^\d+$
     验证非正整数（负整数 + 0） ^((-\d+)|(0+))$
     验证长度为3的字符：^.{3}$
     验证由26个英文字母组成的字符串：^[A-Za-z]+$
     验证由26个大写英文字母组成的字符串：^[A-Z]+$
     验证由26个小写英文字母组成的字符串：^[a-z]+$
     验证由数字和26个英文字母组成的字符串：^[A-Za-z0-9]+$
     验证由数字、26个英文字母或者下划线组成的字符串：^\w+$
     验证用户密码:^[a-zA-Z]\w{5,17}$ 正确格式为：以字母开头，长度在6-18之间，只能包含字符、数字和下划线。
     验证是否含有 ^%&',;=?$\" 等字符：[^%&',;=?$\x22]+
     验证汉字：^[\u4e00-\u9fa5],{0,}$
     验证Email地址：^\w+[-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$
     验证InternetURL：^http://([\w-]+\.)+[\w-]+(/[\w-./?%&=]*)?$ ；^[a-zA-z]+://(w+(-w+)*)(.(w+(-w+)*))*(?S*)?$
     验证电话号码：^(\d3,4|\d{3,4}-)?\d{7,8}$：--正确格式为：XXXX-XXXXXXX，XXXX-XXXXXXXX，XXX-XXXXXXX，XXX-XXXXXXXX，XXXXXXX，XXXXXXXX。
     验证身份证号（15位或18位数字）：^\d{15}|\d{}18$
     验证一年的12个月：^(0?[1-9]|1[0-2])$ 正确格式为：“01”-“09”和“1”“12”
     验证一个月的31天：^((0?[1-9])|((1|2)[0-9])|30|31)$ 正确格式为：01、09和1、31。
     整数：^-?\d+$
     非负浮点数（正浮点数 + 0）：^\d+(\.\d+)?$
     正浮点数 ^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$
     非正浮点数（负浮点数 + 0） ^((-\d+(\.\d+)?)|(0+(\.0+)?))$
     负浮点数 ^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$
     浮点数 ^(-?\d+)(\.\d+)?$
     */


    // 创建一个自定义验证函数
    function check(val, data) {
        if (data.type === "price") {
            if (Number(val) <= data.max && Number(val) >= data.min) {
                return true
            }
        }
        return false
    }

    let bool = true
    if (val === "") return true
    // 1.首先执行正则验证
    reg.map(item => {
        if (!new RegExp(item).test(val)) {
            bool = false
        }
    })
    if (!bool) return false
    // 2.执行自定义验证
    rule.map(item => {
        if (!check(val, item)) {
            bool = false
        }
    })
    return bool
}

// 获取当前导航栏显示path
export function getActive($route) {
    let router = ""
    for (let i = 0; i < $route.matched.length; i++) {
        if ($route.matched[i]?.meta?.route) {
            router = $route.matched[i].path
        }
    }
    return router
}

// table表头处理
export function headerCellStyle({ rowIndex, columnIndex, row }) {
    if (rowIndex === 0) {
        if (columnIndex === 0) {
            return "background: #EBEEF8;border-radius: 1px; border-bottom: 1px solid rgba(201, 202, 218, 1); border-top: 1px solid rgba(201, 202, 218, 1);border-left: 1px solid rgba(201, 202, 218, 1);color:#394359;font-weight:500"
        }
        if (columnIndex === row.length - 1) {
            return "background: #EBEEF8;border-radius: 1px; border-bottom: 1px solid rgba(201, 202, 218, 1); border-top: 1px solid rgba(201, 202, 218, 1);border-right: 1px solid rgba(201, 202, 218, 1);color:#394359 ;font-weight:500"
        }
        return "background: #EBEEF8;border-radius: 1px; border-bottom: 1px solid rgba(201, 202, 218, 1); border-top: 1px solid rgba(201, 202, 218, 1);color:#394359;font-weight:500"
    }
}

export function TransferHeaderCellStyle({ rowIndex, columnIndex, row }) {
    if (rowIndex === 0) {
        if (columnIndex === 0) {
            return "background: #EBEEF8;border-radius: 1px; border-bottom: 1px solid rgba(201, 202, 218, 1); border-top: 1px solid rgba(201, 202, 218, 1); "
        }
        if (columnIndex === row.length - 1) {
            return "background: #EBEEF8;border-radius: 1px; border-bottom: 1px solid rgba(201, 202, 218, 1); border-top: 1px solid rgba(201, 202, 218, 1); "
        }
        return "background: #EBEEF8;border-radius: 1px; border-bottom: 1px solid rgba(201, 202, 218, 1); border-top: 1px solid rgba(201, 202, 218, 1);"
    }
}

// 通过接口引入组件
// export function $import(router) {
//     if (router === "home") {
//         return Layout
//     }
//     return {
//         render: function (h) {
//             return h('div', router)
//         }
//     }
// }
