<template>
  <div class="flex-h-1 flex-column">
    <div class="el-menu-header">
      <menu-top/>
    </div>
    <div class="flex flex-h-1">
      <div class="el-menu-aside">
        <menu-nav/>
        <img
            v-if="!$store.state.app.isCollapse"
            alt="icon"
            class="collapse"
            referrerpolicy="no-referrer"
            src="@/assets/img/home/icon-open.png"
            @click="changeIsCollapse"/>
        <img
            v-else
            alt="icon"
            class="collapse"
            referrerpolicy="no-referrer"
            src="@/assets/img/home/icon-shrink.png"
            @click="changeIsCollapse"/>

      </div>
      <div class="flex flex-w-1 el-main flex-column">
        <el-breadcrumb v-if="matched.length>0" class="f16 breadcrumb user-select" separator="/">
          <template v-for="(item,index) in matched">
            <el-breadcrumb-item v-if="index === matched.length - 1 || index === 0" :key="item.path"
                                class="breadcrumb-item">
              {{ item.meta.name }}
            </el-breadcrumb-item>
            <el-breadcrumb-item v-else :key="item.path" :to="item.path" class="breadcrumb-item" style="color:#6A7387">
              {{ item.meta.name }}
            </el-breadcrumb-item>
          </template>
        </el-breadcrumb>
        <div class="main flex-h-1">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuNav from './menu-nav'
import MenuTop from './menu-top'
import store from "@/store"

export default {
  components: {
    MenuNav,
    MenuTop
  },
  name: "layout",
  data() {
    return {}
  },
  computed: {
    // 列表功能
    matched() {
      return this.$route.matched.filter(item => {
        return !!item?.meta?.name;
      })
    }
  },
  methods: {
    changeIsCollapse() {
      let isCollapse = !store.state.app.isCollapse
      store.commit("app/SET_ISCOLLAPSE", isCollapse)
    }
  },
  created() {

  }
}
</script>

<style lang="less" scoped>
::v-deep .el-breadcrumb__inner{
  color: #6A7387;
}
::v-deep .el-breadcrumb__inner.is-link{
  color: #394359;
}
.el-main {
  border-left: 1px solid #ebeef8;
  padding: 16px;
  box-sizing: border-box;
  background-color: #F2F4F9;
  display: flex !important;

}

.el-menu-aside {
  overflow-y: auto;
  height: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 0 6px 0 rgba(0, 21, 41, 0.1);
  user-select: none;

  .collapse {
    width: 16px;
    height: 16px;
    align-self: flex-end;
    margin-bottom: 32px;
    margin-right: 20px;
  }
}

.el-menu-header {
  user-select: none;
  height: 48px;
  padding: 0;
  background-color: #FFFFFF;
  border-bottom: 1px solid #EBEEF8;
}

.main {
  width: 100%;
  overflow: auto;
  display: flex;
  background-color: #FFFFFF;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/

aside::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
aside::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/

aside::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c8c8c8;
}

.el-breadcrumb {
  height: 26px;
  line-height: 10px;
  font-size: 14px;
}
</style>
