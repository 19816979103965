import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import store from './store'
import VueClipBoard from 'vue-clipboard2'
import Print from 'vue-print-nb'
// Global instruction 
Vue.use(Print);


Vue.config.productionTip = false
// import '@/element/element-variables.scss'
import '@/element/index.less'
import '@/element/theme/index.css'
import '@/css/index.css';
import './permission'

Vue.use(Element);
Vue.use(VueClipBoard);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
