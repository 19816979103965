/*sidebar: state => state.app.sidebar,
    size: state => state.app.size,
    device: state => state.app.device,*/
const getters = {
    active: state => state.app.active,
    token: state => state.app.token,
    userinfo: state => state.app.userinfo,
    userdata: state => state.app.userdata,
    routes: state => {
        if (!state.app.userinfo) {
            return null
        }
        let routes = []
        state.app.userinfo.map((item) => {
            if (item.type === 1) {
                routes.push(item.code)
            }
        })
        return routes
    }
}
export default getters
