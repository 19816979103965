// 所有api都放在此处 

import request from '@/utils/http'

// 登录
export function login(data) {
  return request({ url: '/admin/login', method: 'post', data })
}
// .then(res=>{
//     if(res.result==200){
//         localStorage.setItem('routers',JSON.stringify(res.data.operation))
//     }
// })
// 退出登录
export function loginOut() {
  localStorage.removeItem('routers')
  return new Promise(resolve => { resolve({ result: 200, data: [] }) })
  // return request({
  //     url: '/admin/logout',
  //     method: 'post'
  // })
}

// 获取权限 
export function getRouters() {

  let arr = [
    { code: '/', id: 78, name: '首页', pid: 0, router: '', type: 1 },
    { code: '/home', id: 79, name: '首页页面', pid: 78, router: '', type: 1 },
    { code: '/stock', id: 9, name: '库存管理', pid: 0, router: '', type: 1 }, {
      code: '/stock/express',
      id: 10,
      name: '发货管理',
      pid: 9,
      router: '',
      type: 1
    },
    {
      code: '/stock/warehousing',
      id: 11,
      name: '入库管理',
      pid: 9,
      router: '',
      type: 1
    },
    {
      code: '/order/order',
      id: 15,
      name: '订单管理',
      pid: 9,
      router: '',
      type: 1
    },{
      code: '/bill/bill',
      id: 15,
      name: '对账管理',
      pid: 9,
      router: '',
      type: 1
    },{
      code: '/prescription/prescription',
      id: 15,
      name: '处方开方',
      pid: 9,
      router: '',
      type: 1
    },
    { code: '/drug/drug', id: 12, name: '药品管理', pid: 9, router: '', type: 1 },
    {
      code: '/user/admin',
      id: 13,
      name: '分享者管理',
      pid: 9,
      router: '',
      type: 1
    },{
      code: '/coupon/coupon',
      id: 131,
      name: '优惠券管理',
      pid: 9,
      router: '',
      type: 1
    },
    
    {
      code: '/account/account',
      id: 14,
      name: '账号管理',
      pid: 9,
      router: '',
      type: 1
    },{
      code: '/system/system',
      id: 15,
      name: '系统账号',
      pid: 9,
      router: '',
      type: 1
    },
    {
      code: '/system/authority',
      id: 15,
      name: '权限管理',
      pid: 9,
      router: '',
      type: 1
    }
  ];
  let routersArr = [
    { code: '/', id: 78, name: '首页', pid: 0, router: '', type: 1 },
    { code: '/home', id: 79, name: '首页页面', pid: 78, router: '', type: 1 },
  ]



  arr.forEach(
    element => {
      JSON.parse(localStorage.getItem('routers')).forEach(item => {
        if (element.name == item.name) {
          routersArr.push(element)
        }
      })
    });
  // request({
  //     url: '/auth/operation/index',
  //     method: 'get',
  //     data
  // }).then(res => {
  //     if (res.result == 200) {

  //     }
  // })


  return new Promise(
    resolve => {
      resolve({ message: 'success', result: 200, data: arr })

    })
}


// 获取当前用户信息 
export function getUserInfo() {
  return new Promise(resolve => {
    resolve({
      'result': 200,
      'message': 'success',
      'data': {
        'id': 1,
        'production_id': 0,
        'role_id': 0,
        'username': 'admin',
        'name': '用户',
        'tel': '',
        'status': 1,
        'type': 1,
        'note': null,
        'role_name': '',
        'production_name': '',
        'status_text': '启用'
      }
    })
  })
  // return request({
  //     url: '/admin/current_info',
  //     method: 'get',
  // })
} 
