const TokenKey = 'Admin-Token'

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
}

export function getItem(key) {
    return localStorage.getItem(key)
}

export function setItem(key, value) {
    return localStorage.setItem(key, value)
}

export function removeItem(key) {
    return localStorage.removeItem(key)
}
