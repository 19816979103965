import request from "@/utils/http";

// 获取权限列表（树状）
export function getSourceTree() {
    return request({
        url: '/auth/operation/tree',
        method: 'get'
    })
}

// 获取菜单列表（树状）
export function getSourceMenuTree() {
    return request({
        url: '/auth/operation/menu',
        method: 'get'
    })
}

// 角色列表
export function getRoleList() {
    return request({
        url: '/auth/role/index',
        method: 'get'
    })
}

// 查看角色权限
export function getRoleOperation(params) {
    return request({
        url: '/auth/role/operation',
        method: 'get',
        params
    })
}

// 添加角色
export function addRole(data) {
    return request({
        url: '/auth/role/create',
        method: 'post',
        data
    })
}

// 编辑角色
export function editRole(data) {
    return request({
        url: '/auth/role/update',
        method: 'post',
        data
    })
}

// 删除角色
export function delRole(data) {
    return request({
        url: '/auth/role/delete',
        method: 'post',
        data
    })
}

// 设置角色权限
export function updateRoleOperation(data) {
    return request({
        url: '/auth/role/operation/save',
        method: 'post',
        data
    })
}

