import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
// http://dev-net-his-api.mhzzr.com
// create an axios instance

let baseURL = "http://dev-share-api.mhzzr.com";

// http://dev-admin-share.mhzzr.com  
// http://admin-share.mhzzr.com
if (window.location.host === "dev-admin-share.mhzzr.com") {
    // 测试环境登入
    baseURL = "http://dev-share-api.mhzzr.com"; // pdos测试

} else if (window.location.host === "admin-share.mhzzr.com") {
    baseURL = "http://share-api.mhzzr.com"; // 正式
} else {
    baseURL = "/api";//开发
}
const service = axios.create({
    // baseURL: "http://dev-share-api.mhzzr.com", // url = base url + request url
    baseURL: baseURL,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        if (config.url === '/stock/inbound/chain') {
            config.timeout = 30000
        }
        if (store.getters.token) {
            config.headers['JWT-TOKEN'] = store.getters.token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
// 过滤接口 无需操作
let filter_list = ["/admin/logout"]


// response interceptor 
service.interceptors.response.use( 
    response => {
        const res = response.data
        const { result } = res
        // token 过期失效
        if (result === 202) {
            if (!filter_list.includes(response?.config.url)) {
                store.dispatch("app/loginOut")
            }
            return Promise.reject(new Error(res.message || 'Error'))
        }
        if (result !== 200) {
            Message({
                message: res.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(new Error(res.message || 'Error'))
        }
        return res
    },
    error => {
        Message({
            message: error.message || "异常错误",
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
