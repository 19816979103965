import { getRouters, getUserInfo, login, loginOut } from '@/api';
import router from '@/router';
import { getItem, setItem, setToken } from '@/utils/auth';
import { Loading } from 'element-ui';

const state = {
    active: getItem('active') || '/home',  // 路由缓存
    token: getItem('Admin-Token'),         // token缓存
    userinfo: '',                          // 当前登录用户路由数据
    userdata: '',                          // 用户各类数据
    isCollapse: JSON.parse(getItem('isCollapse')) || false,  // 侧边栏收缩状态
}

const mutations = {
    SET_ACTIVE: (state, active) => {
        state.active = active
        setItem('active', active)
    },
    SET_TOKEN: (state, token) => {
        state.token = token
        setToken(token)
    },
    SET_USERINFO: (state, userinfo) => {
        state.userinfo = userinfo
        // setItem("userinfo", JSON.stringify(userinfo))
    },
    SET_USERDATA: (state, userdata) => {
        state.userdata = userdata
        // setItem("userdata", JSON.stringify(userdata))
    },
    SET_ISCOLLAPSE: (state, isCollapse) => {
        state.isCollapse = isCollapse
        setItem('isCollapse', isCollapse)
    },
}

const actions = {
    // 登录
    login({ commit, dispatch }, params) {
        return new Promise((resolve) => {
            try {
                let loadingInstance = Loading.service({ text: '加载中' });
                const { username, password } = params
                login({
                    username,
                    password
                }).then(async res => {
                    localStorage.setItem('routers', JSON.stringify(res.data.operation))
                    loadingInstance.close()
                    const { result: code, data } = res
                    if (code !== 200) {
                        resolve(false)
                        return
                    }
                    const { operation, token } = data
                    // 添加userinfo   到   vuex 中
                    commit('SET_USERINFO', operation)
                    // 添加token      到   vuex 中
                    commit('SET_TOKEN', token)
                    await dispatch('getUserInfo')
                    resolve(true)
                }).catch(() => {
                    loadingInstance.close()
                    resolve(false)
                })
            } catch (err) {
                resolve(false)
            }
        })
    },
    // 获取用户数据
    async getUserInfo({ commit }) {
        try {
            let { data, result } = await getUserInfo()
            if (result !== 200) return false
            commit('SET_USERDATA', data)
            return true
        } catch (err) {
            return false
        }
    },

    // 退出登录
    loginOut({ commit }) {
        return new Promise(resolve => {
            // 执行退出登录接口
            loginOut()
            // 清除缓存
            localStorage.clear()
            // 处理vuex
            commit('SET_USERINFO', '')
            commit('SET_TOKEN', '')
            commit('SET_USERDATA', '')
            // 前往登录页面
            router.push('/login').then(() => {
                resolve(true)
            }).catch(() => {
                resolve(false)
            })
        })
    },

    // 获取权限
    getRoutes({ commit }) {
        return new Promise((resolve) => {
            try {
                let loadingInstance = Loading.service({ text: '加载中' });
                getRouters().then(async res => {
                    loadingInstance.close()
                    const { result: code, data } = res
                    if (code !== 200) {
                        resolve(false)
                        return
                    }
                    // 添加routes    到    vuex 中
                    commit('SET_USERINFO', data)
                    resolve(true)
                }).catch(() => {
                    loadingInstance.close()
                    resolve(false)
                })
            } catch (e) {
                resolve(false)
            }
        })
    }
}

export default {
    namespaced: true, state, mutations, actions
}
