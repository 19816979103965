<template>
  <div class="top flex-center">
    <!-- <img alt="加载失败" class="logo" src="@/assets/img/logo.png"> -->
    <div class="title">药享OS后台管理</div>
    <div class="flex-w-1"></div>
    <!-- <img alt="error" class="icon" src="@/assets/img/home/icon-search.png">
    <img alt="error" class="icon" src="@/assets/img/home/icon-message.png"> -->
    <el-dropdown placement="top-start" @command="command">
      <div class="flex flex-center" style="width: 100%">
        <img alt="" class="head-img" src="@/assets/img/home/icon-head.png" />
        <div class="head-name">{{ userdata.name || "未知" }}</div>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import store from "@/store";
import { MessageBox } from "element-ui";

export default {
  name: "menu-top",
  computed: {
    userdata() {
      return store.getters.userdata;
    },
  },
  methods: {
    async command(type = "") {
      if (type === "loginOut") {
        try {
          let res = await MessageBox.confirm("是否确认退出账号?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          if (res === "confirm") {
            // 执行退出登录
            await store.dispatch("app/loginOut");
          }
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  display: flex;
}

.logo {
  width: 40px;
  height: 36px;
  margin-left: 16px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #2a2f63;
  line-height: 52px;
  margin-left: 8px;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 24px;
}

.head-img {
  width: 24px;
  height: 24px;
  background: #ebeef8;
  border-radius: 50%;
  margin-right: 8px;
}

.head-name {
  font-size: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.65);
  line-height: 14px;
  margin-right: 24px;
}
</style>
