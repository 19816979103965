import Vue from 'vue'
import Router from 'vue-router'

import Layout from '@/views/Menu/index.vue'
// import {$import} from "@/utils";

Vue.use(Router)

export const routes = [
    // 异常页面管理
    {
        path: '/error',
        name: 'error',
        component: Layout,
        redirect: "/home",
        children: [
            // 抱歉，您没有访问该资源的权限～
            {
                path: '/403',
                name: "error-403",
                component: () => import(/* webpackChunkName: "error-403" */ '../views/Error/error-403'),
            },
            // 抱歉，页面不见了～
            {
                path: '/404',
                name: "error-404",
                component: () => import(/* webpackChunkName: "error-404" */ '../views/Error/error-404'),
            },
            // 抱歉，服务器出了点问题～
            {
                path: '/500',
                name: "error-500",
                component: () => import(/* webpackChunkName: "error-500" */ '../views/Error/error-500'),
            }
        ]
    },
    // 登录
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Login/index')
    },
    // 首页
    {
        path: '/',
        name: '',
        component: Layout,
        redirect: "/home",
        meta: {
            children: true, // 一级导航使用children中的页面渲染
            route: true
        },
        children: [{
            path: '/home',
            name: "home",
            meta: {
                name: "首页",
                route: true
            },
            component: () => import( /* webpackChunkName: "home" */ '@/views/Home/index'),
        }]
    },
    {
        path: '/drug',
        name: 'drug',
        component: Layout,
        redirect: "/drug/drug",
        meta: {
            // name: "药品管理",
            route: true
        },
        children: [
            {
                path: '/drug/drug',
                name: "drug-drug",
                meta: {
                    name: "药品管理",
                    route: true
                },
                component: () => import( /* webpackChunkName: "stock-express" */ '@/views/drug/drug'),


            }
        ]

    },
    {
        path: '/stock',
        name: 'stock',
        component: Layout,
        redirect: "/stock/warehousing",
        meta: {
            name: "库存管理",
            route: true
        },
        children: [
            {
                path: '/stock/warehousing',
                name: "stock-warehousing",
                meta: {
                    name: "入库管理",
                    route: true
                },
                component: () => import( /* webpackChunkName: "stock-express" */ '@/views/Stock/warehousing'),
                children: [
                    {
                        path: '/stock/warehousing/add',
                        name: "stock-warehousing-add",
                        component: () => import( /* webpackChunkName: "stock-express-details" */ '@/views/Stock/components/warehousing-add'),
                        meta: {
                            name: "手动入库"
                        },
                    },
                ]

            }, {
                path: '/stock/express',
                name: "stock-express",
                meta: {
                    name: "发货管理",
                    route: true
                },
                component: () => import( /* webpackChunkName: "stock-express" */ '@/views/Stock/express'),
                children: [
                    {
                        path: '/stock/express/details',
                        name: "stock-express-details",
                        component: () => import( /* webpackChunkName: "stock-express-details" */ '@/views/Stock/components/express-details'),
                        meta: {
                            name: "订单详情"
                        },
                    },
                    {
                        path: '/stock/express/goods',
                        name: "stock-express-goods",
                        component: () => import( /* webpackChunkName: "stock-express-details" */ '@/views/Stock/components/express-goods'),
                        meta: {
                            name: "发货"
                        },
                    }
                ]
            }
        ]
    },
        // 订单
        {
            path: '/order',
            name: 'order',
            component: Layout,
            redirect: "/order/order",
            meta: {
                name: "",
                route: true
            },
            children: [
                // 用户管理
                {
                    path: '/order/order',
                    name: "order-order",
                    meta: {
                        name: "订单管理",
                        route: true
                    },
                    component: () => import( /* webpackChunkName: "user-admin" */ '@/views/orders/orders'),
                    children: [
                        {
                            path: '/order/order/details',
                            name: "order-order-details",
                            component: () => import( /* webpackChunkName: "stock-express-details" */ '@/views/orders/details'),
                            meta: {
                                name: "订单详情"
                            },
                        },
                    ]
                },
    
            ]
        },
         // 对账
         {
            path: '/bill',
            name: 'bill',
            component: Layout,
            redirect: "/bill/bill",
            meta: {
                name: "",
                route: true
            },
            children: [
                // 对账管理
                {
                    path: '/bill/bill',
                    name: "bill-bill",
                    meta: {
                        name: "对账管理",
                        route: true
                    },
                    component: () => import( /* webpackChunkName: "user-admin" */ '@/views/bill/bill'),
                    children: [
                        {
                            path: '/bill/bill/details',
                            name: "order-order-details",
                            component: () => import( /* webpackChunkName: "stock-express-details" */ '@/views/bill/details'),
                            meta: {
                                name: "账单明细"
                            },
                        },
                    ]
                },
    
            ]
        },
        // 处方
        {
            path: '/prescription',
            name: 'prescription',
            component: Layout,
            redirect: "/prescription/prescription",
            meta: {
                // name: "处方开方",
                route: true
            },
            children: [
                // 处方
                {
                    path: '/prescription/prescription',
                    name: "prescription-prescription",
                    meta: {
                        name: "处方开方",
                        route: true
                    },
                    component: () => import( /* webpackChunkName: "user-admin" */ '@/views/prescription/prescription'),
                    children: [
                        {
                            path: '/prescription/prescription/details',
                            name: "prescription-prescription-details",
                            component: () => import( /* webpackChunkName: "stock-express-details" */ '@/views/prescription/details'),
                            meta: {
                                name: "处方详情"
                            },
                        },
                    ]
                },
    
            ]
        },
    // 分享者
    {
        path: '/user',
        name: 'user',
        component: Layout,
        redirect: "/user/admin",
        meta: {
            // name: "分享者管理",
            route: true
        },
        children: [
            // 用户管理
            {
                path: '/user/admin',
                name: "user-admin",
                meta: {
                    name: "分享者管理",
                    route: true
                },
                component: () => import( /* webpackChunkName: "user-admin" */ '@/views/User/admin'),
                 
            },

        ]
    },
    //优惠券
    {
        path: '/coupon',
        name: 'coupon',
        component: Layout,
        redirect: "/coupon/coupon",
        meta: {
            // name: "分享者管理",
            route: true
        },
        children: [
            // 用户管理
            {
                path: '/coupon/coupon',
                name: "coupon-coupon",
                meta: {
                    name: "分享者管理",
                    route: true
                },
                component: () => import( /* webpackChunkName: "user-admin" */ '@/views/coupon/coupon'),
                 
            },

        ]
    },

    // 
    // 账号
    {
        path: '/account',
        name: 'account',
        component: Layout,
        redirect: "/account/account",
        meta: {
            // name: "分享者管理",
            route: true
        },
        children: [
            // 用户管理
            {
                path: '/account/account',
                name: "account-account",
                meta: {
                    name: "账号管理",
                    route: true
                },
                component: () => import( /* webpackChunkName: "user-admin" */ '@/views/account/account'),
                 
            },

        ]
    },
    // 权限
    {
        path: '/system',
        name: 'system',
        component: Layout,
        redirect: "/system/system",
        meta: {
            name: "系统管理",
            route: true
        },
        children: [
            // 用户管理
            {
                path: '/system/authority',
                name: "system-authority",
                meta: {
                    name: "权限管理",
                    route: true
                },
                component: () => import( /* webpackChunkName: "user-admin" */ '@/views/system/authority'),
                 
            },{
                path: '/system/system',
                name: "system-system",
                meta: {
                    name: "系统账号",
                    route: true
                },
                component: () => import( /* webpackChunkName: "user-admin" */ '@/views/system/system'),
                 
            },

        ]
    },
]
const router = new Router({
    mode: "hash",
    routes
})
export default router
