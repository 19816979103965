import store from "@/store";
import router from "@/router";
import {getToken} from "@/utils/auth";
import {getActive} from "@/utils";

// 白名单->
const whiteList = ["/404", "/403", "/500"]

router.beforeEach(async (to, from, next) => {
    const hasToken = getToken() || store.getters.token
    // login页面直达
    if (to.path === "/login") {
        next()
        return
    }
    // token不存在->前往登录
    if (!hasToken) {
        // 执行方法 loginOut -> 清除缓存
        next({path: '/login'})
        return
    }
    if (whiteList.indexOf(to.path) !== -1) {
        await store.commit("app/SET_ACTIVE", "")
        next()
        return
    }
    let routes = store.getters.routes
    // 权限尚未加载
    if (!routes) {
        await store.dispatch("app/getRoutes")
        routes = store.getters.routes
        if (!routes) {
            next({path: '/404'})
            return
        }
    }
    let userdata = store.getters.userdata
    if (!userdata) {
        await store.dispatch("app/getUserInfo")
        userdata = store.getters.userdata
    }
    // 权限外页面->不允许通行->前往403页面
    if (routes.indexOf(to.path) === -1) {
        // 过滤三级页面,如果二级页面存在则通行
        try {
            if (routes.indexOf(to.matched[1].path) === -1) {
                next("/home")
                return
            }
        } catch (e) {
            next("/home")
        }
    }
    await store.commit("app/SET_ACTIVE", getActive(to))
    next()
})
