<template>
  <el-menu
    :collapse="isCollapse"
    :default-active="$store.getters.active"
    :router="true"
    :unique-opened="true"
    active-text-color="#2A2F63"
    class="el-menu-vertical-demo"
    text-color="#394359"
    @select="select"
  >
    <el-menu-item v-if="showNav('/home')" index="/home">
      <img
        alt="icon"
        class="icon"
        referrerpolicy="no-referrer"
        src="@/assets/img/home/首页@2x.png"
      />
      <span slot="title" class="isActiveTitle"
        ><span class="line"></span> 首页</span
      >
    </el-menu-item>
    <el-menu-item v-if="showNav('/drug/drug')" index="/drug/drug">
      <img
        alt="icon"
        class="icon"
        referrerpolicy="no-referrer"
        src="@/assets/img/home/药品管理@2x.png"
      />
      <span slot="title" class="isActiveTitle"
        ><span class="line"></span>药品管理</span
      >
    </el-menu-item>
    <!-- <el-menu-item v-if="showNav('/stock/warehousing')" index="/stock/warehousing">
      <img alt="icon" class="icon" referrerpolicy="no-referrer" src="@/assets/img/home/库存管理@2x.png" />
      <span slot="title">库存管理</span>
    </el-menu-item> -->
    <el-submenu v-if="showNav('/stock')" index="/stock">
      <template slot="title">
        <img
          alt="icon"
          class="icon"
          referrerpolicy="no-referrer"
          src="@/assets/img/home/库存管理@2x.png"
        />
        <span slot="title" class="isActiveTitle"
          ><span class="line"></span>库存管理</span
        >
      </template>
      <el-menu-item v-if="showNav('/stock/express')" index="/stock/express"
        >发货管理</el-menu-item
      >
      <el-menu-item
        v-if="showNav('/stock/warehousing')"
        index="/stock/warehousing"
      >
        库存管理
      </el-menu-item>
    </el-submenu>
    <el-menu-item v-if="showNav('/order/order')" index="/order/order">
      <img
        alt="icon"
        class="icon"
        referrerpolicy="no-referrer"
        src="@/assets/img/home/订单管理@2x.png"
      />
      <span slot="title" class="isActiveTitle"
        ><span class="line"></span>订单管理</span
      >
    </el-menu-item>
     <el-menu-item v-if="showNav('/bill/bill')" index="/bill/bill">
      <img
        alt="icon"
        class="icon"
        referrerpolicy="no-referrer"
        src="@/assets/img/home/对账管理@2x.png"
      />
      <span slot="title" class="isActiveTitle"
        ><span class="line"></span>对账管理</span
      >
    </el-menu-item>
    <el-menu-item
      v-if="showNav('/prescription/prescription')"
      index="/prescription/prescription"
    >
      <img
        alt="icon"
        class="icon"
        referrerpolicy="no-referrer"
        src="@/assets/img/home/处方开方@2x.png"
      />
      <span slot="title" class="isActiveTitle"
        ><span class="line"></span>处方开方</span
      >
    </el-menu-item>
    <el-menu-item v-if="showNav('/user/admin')" index="/user/admin">
      <img
        alt="icon"
        class="icon"
        referrerpolicy="no-referrer"
        src="@/assets/img/home/分享者管理@2x.png"
      />
      <span slot="title" class="isActiveTitle"
        ><span class="line"></span>分享者管理</span
      >
    </el-menu-item>
    <el-menu-item v-if="showNav('/coupon/coupon')" index="/coupon/coupon">
      <img
        alt="icon"
        class="icon"
        referrerpolicy="no-referrer"
        src="@/assets/img/home/分享者管理@2x.png"
      />
      <span slot="title" class="isActiveTitle"
        ><span class="line"></span>优惠券管理</span
      >
    </el-menu-item>

    <el-menu-item v-if="showNav('/account/account')" index="/account/account">
      <img
        alt="icon"
        class="icon"
        referrerpolicy="no-referrer"
        src="@/assets/img/home/账号管理@2x.png"
      />
      <span slot="title" class="isActiveTitle"
        ><span class="line"></span>账号管理</span
      >
    </el-menu-item>
    <el-submenu v-if="showNav('/system')" index="/system">
      <template slot="title">
        <img
          alt="icon"
          class="icon"
          referrerpolicy="no-referrer"
          src="@/assets/img/home/优惠券管理@2x.png"
        />
        <span slot="title" class="isActiveTitle"
          ><span class="line"></span>系统管理</span
        >
      </template>
      <el-menu-item v-if="showNav('/system/system')" index="/system/system">
        <span slot="title">系统账号</span>
      </el-menu-item>
      <el-menu-item
        v-if="showNav('/system/authority')"
        index="/system/authority"
      >
        <span slot="title">权限管理</span>
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
import store from "@/store";
import { getActive } from "@/utils";
import { getRoleOperation } from "@/api/operation";

export default {
  name: "menu-nav",
  data() {
    return {};
  },
  computed: {
    isCollapse() {
      return JSON.parse(store.state.app.isCollapse);
    },
    userdata() {
      return store.getters.userdata;
    },
  },
  created() {
    store.commit("app/SET_ACTIVE", getActive(this.$route));
  },
  methods: {
    getRoleOperationAxios() {
      getRoleOperation({ role_id: this.userdata.id });
    },
    // 渲染侧边栏
    showNav(router) {
      let res = store.getters.routes.includes(router);
      if (res) {
        return true;
      }
      // return true
      return store.getters.routes.find((item) => {
        let end = item.indexOf("/", 1);
        if (end > 0) {
          res = item.substring(0, end);
          return res === router;
        }
      });
    },
    // 路由导航留痕
    select(to) {
      store.commit("app/SET_ACTIVE", to);
    },
  },
};
</script>
<style lang="less">
.el-menu-aside {
  .el-submenu__title,
  .el-menu-item {
    height: 46px !important;
    line-height: 46px !important;
  }

  .is-active {
    background-color: #f0f1fb !important;
    position: relative;

    ul {
      background-color: #f0f1fb !important;
    }
  }
  .el-menu-item.is-active {
    font-weight: 500;
  }
  .is-active span.isActiveTitle .line {
    // display: inline-block;
    position: absolute;
    left: 0;
    width: 3px;
    height: 100%;
    background: #2a2f63;
    border-radius: 0px 8px 8px 0px;
  }
  .el-submenu {
    li {
      position: relative;
    }

    li::before {
      content: "";
      width: 6px;
      height: 6px;
      border: 1px solid #9fa9bd;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
      box-sizing: border-box;
      border-radius: 50%;
    }

    li.is-active::before {
      content: "";
      width: 6px;
      height: 6px;
      background-color: #2a2f63;
      border: 0;
      border-radius: 50%;
    }
  }
}
</style>

<style lang="less" scoped>
.el-menu {
  border: 0;
  flex: 1;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
</style>
